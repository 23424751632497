<template>
  <div>
    <button @click="startRecording" :disabled="isRecording">Start Recording</button>
    <button @click="stopRecording" :disabled="!isRecording">Stop Recording</button>
    <button @click="saveRecording" :disabled="!recordedAudio">Save Recording</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRecording: false,
      mediaRecorder: null,
      recordedAudio: null,
      audioChunks: []
    };
  },
  methods: {
    startRecording() {
      // Check if the browser supports the MediaDevices API
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.addEventListener('dataavailable', this.handleDataAvailable);
            this.mediaRecorder.addEventListener('stop', this.handleStop);
            this.mediaRecorder.start();
            this.isRecording = true;
          })
          .catch(error => {
            console.error('Error accessing media devices:', error);
          });
      } else {
        // Fallback to the older getUserMedia API
        navigator.getUserMedia = (navigator.getUserMedia ||
                                  navigator.webkitGetUserMedia ||
                                  navigator.mozGetUserMedia ||
                                  navigator.msGetUserMedia);

        if (navigator.getUserMedia) {
          navigator.getUserMedia({ audio: true }, stream => {
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.addEventListener('dataavailable', this.handleDataAvailable);
            this.mediaRecorder.addEventListener('stop', this.handleStop);
            this.mediaRecorder.start();
            this.isRecording = true;
          }, error => {
            console.error('Error accessing media devices:', error);
          });
        } else {
          console.error('MediaDevices API not supported');
        }
      }
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },
    handleDataAvailable(event) {
      this.audioChunks.push(event.data);
    },
    handleStop() {
      this.recordedAudio = new Blob(this.audioChunks, { type: 'audio/wav' });
      this.audioChunks = [];
    },
    saveRecording() {
      const url = URL.createObjectURL(this.recordedAudio);
      const a = document.createElement("a");
      a.href = url;
      a.download = "recording.wav";
      a.click();
    }
  }
};
</script>