<template>
  <div>
    <div class="fw-bold">Rendez-vous</div>

    <div class="row align-items-stratch">
      <div class="col-xl-3 col-6 my-3">
        <label for=""> Date </label>
        <div>
          {{ appointment.date }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Heure </label>
        <div>
          {{ appointment.time }}
        </div>
      </div>
    </div>

    <hr />
    <div class="fw-bold">Informations client</div>
    <div class="row">
      <div class="col-xl-3 col-6 my-3 my-3">
        <label for=""> Prénom : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.firstName }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Nom : </label>
        <div v-if="appointment.customer">
          {{ appointment.customer.lastName }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 my-3">
        <label for=""> Observation </label>
        <textarea
          v-model="validateAppointment.remark"
          class="form-control"
          rows="6"
        ></textarea>
      </div>

      <div class="col-12 my-3">
        <button
          @click="openFileDialog"
          class="btn btn-primary border-primary w-100 p-4"
        >
          Télécharger des photos
        </button>
        <input
          type="file"
          multiple
          @change="uploadFiles"
          ref="fileInput"
          accept="image/*"
          hidden
        />
      </div>

      <div class="row align-items-stretch">
        <div
          v-for="(preview, index) in imagePreviews"
          :key="index"
          class="col-xl-3 col-md-3 col-6 px-xl-2 py-xl-3"
        >
          <div class="card rounded-0 h-100">
            <img :src="preview" alt="Image Preview" class="w-100" />
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div>
      <audio-recorder></audio-recorder>
    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-auto">
        <button class="btn btn-danger" @click="cancel(appointment)">
          <i class="bi bi-x-octagon-fill"></i> Annuler
        </button>
      </div>
      <div class="col-auto">
        <button class="btn btn-success" @click="validate(validateAppointment)">
          <i class="bi bi-check-square-fill"></i> Valider
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AudioRecorder from "../../components/cards/AudioRecorder.vue";

export default {
  components: {
    AudioRecorder,
  },
  data() {
    return {
      photos: [],
      imagePreviews: [],
      validateAppointment: {},
    };
  },
  computed: {
    ...mapGetters("appointment", {
      appointment: "getAppointment",
    }),
  },
  methods: {
    async cancel(data) {
      await this.$store.dispatch("appointment/store", data);
    },

    async validate(data) {
      await this.savePhotos();
      data.reference = this.appointment.reference;
      data.repair_reference = this.appointment.repair_reference;
      data.customer_reference = this.appointment.customer_reference;
      await this.$store.dispatch("appointment/validate", data);
    },

    uploadFiles() {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.photos.push(file);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreviews.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }

      // Implement your file upload logic here
      // and return a Promise that resolves when the uploads are complete
    },

    async savePhotos() {
      const formData = new FormData();
      for (let i = 0; i < this.photos.length; i++) {
        formData.append("photos[]", this.photos[i]);
      }
      await this.$store.dispatch("repair/storePhotos", formData);
    },

    openFileDialog() {
      this.$refs.fileInput.click();
    },
  },
  async beforeMount() {
    // await this.$store.dispatch("employee/getAll");
    // await this.$store.dispatch("order/getAll");
    // await this.$store.dispatch("customer/getAll");
    // await this.$store.dispatch("supplier/getAll");
    // await this.$store.dispatch("repair/getAll");
    await this.$store.dispatch(
      "appointment/show",
      this.$route.params.reference
    );

    await this.$store.dispatch(
      "repair/show",
      this.appointment.repair_reference
    );
  },
};
</script>
